audio { display:none;}

.App {
  position: relative;
  width: 90%;
  height: 100%;
  margin: 0 auto;
  margin-top: 1rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}

@media (max-width: 768px) {
  .App {
    width: 85%;
    height: 100%;
    margin: 0 auto;
  }
}

.single__page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  overflow: hidden;
  background-color: rgb(6, 6, 6);
  cursor: grab;
}

.single__page img {
  max-width:100%;
  max-height:100%;
  margin: 0 auto;
  object-fit: contain;
}

.flip__page {
  position: relative;
  z-index: -1;
}

.rfp-touchZone {
  cursor: pointer;
}

.rfp-touchZone:hover {
  background-color: rgba(0, 0, 0, 0.1);
}



/* Controles */

.controls {
  position: fixed !important;
  right: 0;
  bottom: 20px;
  width: 100%;
  height: 30px;
  text-align: center;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  width: max-content;
  background-color: #f5e5cd;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  opacity: 0.9;
  /* -webkit-transform: translateX(-50%);
  transform: translateX(-50%); */
}

@media (max-width: 1080px) {
  .controls {
    right: 50px;
    padding: 0.2rem;
  }
}

.controls button {
  width: 2rem;
  height: 2rem;
  margin: 0 0.5rem;
  padding: 0.3rem;
  border: none;
  border-radius: 5px;
  background-color: #0163a4;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  opacity: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.controls .help__button {
  border-radius: 50%;
}

.controls .play__button {
  border-radius: 50%;
}

.controls button:hover {
  background-color: #002741;
}

.controls .disabled {
  opacity: 0.5;
  cursor: default;
}

.controls .disabled:hover {
  background-color: #0163a4;
}

.controls > p {
  width: 100px;
  text-align: center;
  font-family: Futura, sans-serif;
  color: rgb(19, 19, 19);
  line-height: 1.1rem;
}

@media (max-width: 768px) {
  .controls > p {
    width: 90px;
    font-size: 14px;
    text-align: center;
  }
}

/* Sound Controls */
.sound__controls {
  position: relative;
  z-index: 9999;
  background-color: transparent;
  margin-top: -5rem; /* QUITAR O CORREGIR */
}

/* Sound Icon */
.sound__controls .sound__icon {
  border-radius: 50%;
  background-color: #0163a4;
  color: #fff;
  cursor: pointer;
  margin-left: 1rem;
  border: none;
  width: 3rem;
  height: 3rem;
  text-align: center;
}

/* @media (max-width: 768px) {
  .sound__icon {
    top: -1.7rem;
    right: -5%;
    position: relative;
  }
} */

/* Popup */
/* .popup {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  visibility: hidden;

} */



