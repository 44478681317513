.finalpage {
  background-image: url('../pages/Portada/finalPage.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}

.finalpage h2 {
  font-size: 1.2rem;
  color: #0163a4;
  font-weight: bold;
  margin-bottom: 1rem;
}

.finalpage a {
  text-decoration: none;
}

.finalpage a > button {
  border: none;
  width: 100%;
  background-color: #0163a4;
  color: #fff;
  padding: 10px;
  font-weight: bold;
}

.finalpage p {
  font-size: 1.1rem;
  margin: 1rem 0;
  border-bottom: 1px solid #0163a4;
  color: #0163a4;
}
