/* .help__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: red;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.help {
  position: fixed;
  /* width: 300px;
  height: 300px; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: 0px;
  margin-top: 0px;
  background-color: #f5e5cd;
  color: #fff;
  opacity: 1;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
}

@media (min-width: 1080px) {
  .help {
    margin-left: 0;
    width: 400px;
    height: 400px;
    margin-top: 0;
  }
}

.help h2 {
  font-size: 1.rem;
  margin: 0;
  padding: 0.5rem;
  text-align: center;
  font-weight: bold;
  color: #0163a4;
  font-family: Futura, sans-serif;
}

.help img {
  width: 220px;
  aspect-ratio: 1/1;
}

@media (min-width: 1080px) {
  .help img {
    width: 300px;
    object-fit: contain;
    aspect-ratio: 1/1;
  }
}

.help__close {
  position: relative;
  top: 0;
  right: 0;
  background-color: #0163a4;
  cursor: pointer;
  color: #fff;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  text-align: right;
}

.help__close p {
  margin-right: 1rem;
}